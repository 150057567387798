.specialisations__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem 4rem;
}

.card.specialisation {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.specialisation__icon {
    background: var(--color-primary);
    padding: 0.6rem;
    border-radius: var(--border-radius-3);
    color: var(--color-white);
    transition: var(--transition);
}

.specialisation:hover .specialisation__icon {
    background: var(--color-light);
    color: var(--color-primary);
}

.specialisation h4 {
    margin-bottom: 0.7rem;
}

@media screen and (max-width: 1024px) {
    .specialisations__container {
        gap: 1.5rem;
    }
}

@media screen and (max-width: 600px) {
    .specialisations__container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }

    .card.specialisation {
        gap: 1.2rem;
    }
}
