#tech-stacks {
    padding-bottom: 0;
}

.swiper {
    height: 30rem;
    padding-bottom: 12rem;
    padding-top: 1rem;
}

.swiper-slide {
    margin-top: 2rem;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.tech-stack__logo {
    width: 15rem;
    aspect-ratio: 1/1;
    border-radius: var(--border-radius-2);
    overflow: hidden;
    transition: var(--transition);
}

.swiper-pagination {
    margin-bottom: 8rem;
}

.swiper-pagination-bullet-active {
    background-color: var(--color-primary) !important;
}
